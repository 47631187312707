import React, { ReactNode } from 'react';
import { FormikProps, FormikValues } from 'formik';
import { Dayjs } from 'dayjs';
import DateRangePicker from '../components/atoms/DateRangePicker';
import FilterInput from '../components/atoms/FilterInput';
import FilterSelect from '../components/atoms/FilterSelect';
import { FilterGridItem, GridItem } from '../components/molecules/GridLayout';
import DateTimePicker from '../components/atoms/DateTimePicker';
import { phoneFormat } from './data-format';

export enum FilterElementType {
	INPUT,
	NUMBER_INPUT,
	PHONE_INPUT,
	SELECT,
	OPTIONAL_SELECT,
	CUSTOM,
	DATE_PICKER,
	DATE_RANGE_PICKER,
}

export interface FormOption {
	label: string;
	value: any;
}

export interface FilterFormInfo {
	key: string | string[];
	type: FilterElementType;
	label?: string;
	placeholder?: string;
	required?: boolean;
	render?: (renderProps: FormikProps<FormikValues>) => any;
	onChange?: (value: any) => void;
	options?: Array<FormOption>;
	span?: number;
	extraOptions?: Record<string, any>;
	optionalActivedKey?: string;
	optionalActivedValue?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const renderFormElement = ({
	key,
	type,
	label = '',
	options,
	placeholder = '입력',
	isFilterForm,
	formik,
	optionalActivedKey,
	optionalActivedValue,
}: {
	key: string | string[];
	type: FilterElementType;
	options?: FormOption[];
	label?: string;
	placeholder?: string;
	isFilterForm?: boolean;
	optionalActivedKey?: string;
	optionalActivedValue?: string;
	formik: any;
}) => {
	const { values, setFieldValue } = formik;

	// 다시 발주처 검색창 막을시 사용
	// const isDisabled =
	// 	key === 'proxyInspectionOrderCompanyName' &&
	// 	window.location.pathname.includes('/car-service/partner');

	let value: any;

	if (typeof key === 'string') {
		value = values[key];
	}

	const renderGridItem = (children: ReactNode) => {
		if (isFilterForm) {
			return (
				<FilterGridItem
					key={key as string}
					labelName={key as string}
					title={label}
				>
					{children}
				</FilterGridItem>
			);
		}

		return (
			<GridItem key={key as string} labelName={key as string} title={label}>
				{children}
			</GridItem>
		);
	};

	/* 필터 타입에 따라서 필터 아이템 렌더링 새로운 UI 필요시 여기에 추가 */
	switch (type) {
		case FilterElementType.INPUT: {
			return renderGridItem(
				<FilterInput
					name={key as string}
					id={key as string}
					value={value || ''}
					onChange={formik.handleChange}
					placeholder={placeholder}
					// disabled={isDisabled}
				/>,
			);
		}
		case FilterElementType.PHONE_INPUT: {
			return renderGridItem(
				<FilterInput
					name={key as string}
					id={key as string}
					value={value ? phoneFormat(value) : ''}
					onChange={(e) => {
						formik.setFieldValue(key as string, phoneFormat(e.target.value));
					}}
					placeholder={placeholder}
				/>,
			);
		}
		case FilterElementType.SELECT:
			return renderGridItem(
				<FilterSelect
					name={key as string}
					id={key as string}
					value={value || ''}
					onChange={formik.handleChange}
					options={options}
				/>,
			);
		case FilterElementType.OPTIONAL_SELECT:
			return optionalActivedKey &&
				formik.values[optionalActivedKey] === optionalActivedValue
				? renderGridItem(
						<FilterSelect
							name={key as string}
							id={key as string}
							value={value || ''}
							onChange={formik.handleChange}
							options={options}
						/>,
				  )
				: renderGridItem(
						<FilterSelect
							name={key as string}
							id={key as string}
							value=""
							options={options}
							disabled
						/>,
				  );
		case FilterElementType.DATE_RANGE_PICKER: {
			return renderGridItem(
				<DateRangePicker
					name={key as string}
					id={key as string}
					value={value || []}
					onChange={(rangeValue) => setFieldValue(key as string, rangeValue)}
					allowClear={false}
				/>,
			);
		}
		case FilterElementType.DATE_PICKER: {
			return renderGridItem(
				<DateTimePicker
					name={key as string}
					id={key as string}
					value={value}
					onChange={(rangeValue: Dayjs | string) =>
						setFieldValue(key as string, rangeValue)
					}
					placeholder={label ?? ''}
					isGrid
				/>,
			);
		}
		default:
			return '';
	}
};
