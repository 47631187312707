import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Skeleton, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import GridDetailTemplate from '../GridDetailTemplate';
import ContentBox from '../../atoms/ContentBox';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import Tab from '../../atoms/Tab';
import GridLayout, {
	GridItem,
	GridTitleSize,
} from '../../molecules/GridLayout';
import Input from '../../atoms/Input';
import DateTimePicker from '../../atoms/DateTimePicker';
import Select from '../../atoms/Select';
import {
	ALL_OPTION_VALUE,
	INSPECTION_CANCELLATION_TYPE,
	INSPECTION_ORDER_TYPE,
	INSPECTION_RECEPTION_STATE_CODE,
	INSPECTION_RESULT_TYPE,
} from '../../../utils/const';
import TextArea from '../../atoms/TextArea';
import { numberWithCommas, phoneFormat } from '../../../utils/data-format';
import CallHistoryTabPane from './CallHistoryTabPane';
import { CustomErrorType, ObjectResponse } from '../../../store/apis/@types';
import { CarServiceDto } from '../../../interface/carService';
import { ToastContext } from '../../../contexts/Toast';
import BillingTabPane from './BillingTabPane';
import { RootState } from '../../../store';
import SettlementTabPane from './SettlementTabPane';
import InspectionPaymentTabPane from './InspectionPaymentTabPane';
import InspectionPaymentStatusTabPane from './InspectionPaymentStatusTabPane';
import TransferHistoryTabPane from './TransferHistoryTabPane';

const TabWrapper = styled.div`
	display: flex;
	margin-bottom: 12px;
`;

const TabList = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: space-between;
`;

const TabPaneWrapper = styled.div``;

interface HandleTabPaneProps {
	toUse: boolean;
	open: boolean;
	handleTabPane: (() => void) | undefined;
	onCloseTab: (() => void) | undefined;
}

interface CarServiceDetailProps {
	inspectionId: string | undefined;
	callHistoryTabPane: HandleTabPaneProps;
	settlementTabPane: HandleTabPaneProps;
	inspectionPaymentTabPane: HandleTabPaneProps;
	inspectionPaymentStatusTabPane: HandleTabPaneProps;
	transferHistoryTabPane: HandleTabPaneProps;
	billingTabPane: HandleTabPaneProps;
	initialValues: Partial<CarServiceDto> | undefined;
	updateCarServiceDetail: MutationTrigger<
		MutationDefinition<
			{ inspectionId: string | undefined; body: Partial<CarServiceDto> },
			any,
			any,
			ObjectResponse<Partial<CarServiceDto>>,
			'api'
		>
	>;
	refetch: () => void;
	isLoading: boolean;
	isUpdating: boolean;
	partnerCase?: boolean;
}

const CarServiceDetailTemplate = ({
	inspectionId,
	callHistoryTabPane,
	settlementTabPane,
	inspectionPaymentTabPane,
	inspectionPaymentStatusTabPane,
	transferHistoryTabPane,
	billingTabPane,
	initialValues,
	updateCarServiceDetail,
	refetch,
	isLoading,
	isUpdating,
	partnerCase = false,
}: CarServiceDetailProps) => {
	const setToast = useContext(ToastContext);
	const [editAll, setEditAll] = useState(false);
	const navigate = useNavigate();

	const { user } = useSelector((state: RootState) => state.auth);

	const transferRole =
		user &&
		user.role.roleItems
			.filter((item) => item.isRead)
			.find((item) => {
				return item.roleTemplate.roleName.replaceAll(' ', '') === '입금관리';
			});

	const settlementRole =
		user &&
		user.role.roleItems
			.filter((item) => item.isRead)
			.find((item) => {
				return item.roleTemplate.roleName.replaceAll(' ', '') === '정산관리';
			});

	const billingRole =
		user &&
		user.role.roleItems
			.filter((item) => item.isCreate)
			.find((item) => {
				return (
					item.roleTemplate.roleName.replaceAll(' ', '') === '지급요청협력'
				);
			});

	const inspectionAgencyAdminRole =
		user &&
		user.role.roleItems
			.filter((item) => item.isDelete)
			.find((item) => {
				return (
					item.roleTemplate.roleName.replaceAll(' ', '') === '통화및이체관리'
				);
			});

	const handleSubmit = async (values: Partial<CarServiceDto>) => {
		const res = await updateCarServiceDetail({
			inspectionId,
			body: values,
		});

		if ('error' in res) {
			setToast(
				'error',
				(res.error as CustomErrorType).data.translate ||
					(res.error as CustomErrorType).data.message,
			);
		} else {
			refetch();
			setToast('info', '수정이 완료되었습니다.');
		}
	};

	const {
		values: formikValues,
		handleChange,
		setFieldValue,
		handleSubmit: formikSubmit,
	} = useFormik({
		initialValues: { ...initialValues },
		enableReinitialize: true,
		onSubmit: handleSubmit,
	});

	return (
		<GridDetailTemplate
			detailTitle="카서비스 상세정보"
			onBack={() => navigate(-1)}
			rightAccessory={
				<Button
					size="small"
					$buttonType={ButtonTypeType.PRIMARY}
					onClick={() => formikSubmit()}
					disabled={isUpdating}
				>
					{isUpdating ? '수정중...' : '수정하기'}
				</Button>
			}
		>
			<Row gutter={[12, 12]}>
				<Col span={14}>
					<Button
						onClick={() => setEditAll(!editAll)}
						$buttonType={
							editAll ? ButtonTypeType.PRIMARY_LIGHT : ButtonTypeType.GHOST
						}
						size="small"
						style={{ marginBottom: 16 }}
					>
						{editAll ? '전체편집중' : '전체편집'}
					</Button>
					{isLoading ? (
						<Skeleton active style={{ marginTop: '1rem' }} />
					) : (
						<Row gutter={[6, 6]}>
							<Col order={1} span={24}>
								<ContentBox borderRadius="10px" $isCompact>
									<GridLayout $columnCount={3} $isCompact>
										<GridItem
											key="carLicensePlateNumber"
											title="차량번호"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Input
												name="carLicensePlateNumber"
												placeholder="차량번호"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled
												value={formikValues.carLicensePlateNumber ?? ''}
												onChange={handleChange}
												onReset={() =>
													setFieldValue('carLicensePlateNumber', '')
												}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="proxyInspectionReceptionChannel"
											title="접수처"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Input
												name="proxyInspectionReceptionChannel"
												placeholder="접수처"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={!editAll || partnerCase}
												value={
													formikValues.proxyInspectionReceptionChannel ?? ''
												}
												onChange={handleChange}
												onReset={() =>
													setFieldValue('proxyInspectionReceptionChannel', '')
												}
												$isCompact
											/>
										</GridItem>
										{!partnerCase && (
											<GridItem
												key="proxyInspectionReceptionRegistrationDate"
												title="접수요청일"
												size={GridTitleSize.GT_X_SMALL}
												$isCompact
											>
												<Tag color="blue" style={{ fontSize: '13px' }}>
													{formikValues.proxyInspectionReceptionRegistrationDate ||
														'없음'}
												</Tag>
												<DateTimePicker
													name="proxyInspectionReceptionRegistrationDate"
													onChange={(date, dateString) => {
														setFieldValue(
															'proxyInspectionReceptionRegistrationDate',
															dateString,
														);
													}}
													placeholder="접수요청일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											</GridItem>
										)}
										<GridItem
											key="modifyCarLicensePlateNumber"
											title="변경번호"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Input
												name="modifyCarLicensePlateNumber"
												placeholder="변경번호"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={formikValues.modifyCarLicensePlateNumber ?? ''}
												onChange={handleChange}
												onReset={() =>
													setFieldValue('modifyCarLicensePlateNumber', '')
												}
												$isCompact
											/>
										</GridItem>
										{!partnerCase && (
											<GridItem
												key="lastCustomerCallDate"
												title="최종통화일"
												size={GridTitleSize.GT_X_SMALL}
												$isCompact
											>
												<Tag color="blue" style={{ fontSize: '13px' }}>
													{formikValues.lastCustomerCallDate || '없음'}
												</Tag>
												<DateTimePicker
													name="lastCustomerCallDate"
													onChange={(date, dateString) => {
														setFieldValue('lastCustomerCallDate', dateString);
													}}
													placeholder="최종통화일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											</GridItem>
										)}
										<GridItem
											key="proxyInspectionReceptionStateCode"
											title="진행상태"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Select
												value={
													formikValues.proxyInspectionReceptionStateCode ?? ''
												}
												onChange={(value: string) =>
													setFieldValue(
														'proxyInspectionReceptionStateCode',
														value,
													)
												}
												placeholder="진행상태"
												style={{ minWidth: '110px', fontSize: '14px' }}
												allowClear
												disabled={partnerCase}
											>
												{INSPECTION_RECEPTION_STATE_CODE.filter(
													(item) => item.value !== ALL_OPTION_VALUE,
												).map((item, idx) => (
													<Select.Option
														value={item.value}
														key={idx.toString()}
													>
														{item.label}
													</Select.Option>
												))}
											</Select>
										</GridItem>
										<GridItem
											key="carName"
											title="차량모델"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Input
												name="carName"
												placeholder="차량모델"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={!editAll || partnerCase}
												value={formikValues.carName ?? ''}
												onChange={handleChange}
												onReset={() => setFieldValue('carName', '')}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="carRegistrationDate"
											title="차량등록일"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Tag color="blue" style={{ fontSize: '13px' }}>
												{formikValues.carRegistrationDate || '없음'}
											</Tag>
											{!partnerCase && (
												<DateTimePicker
													name="carRegistrationDate"
													onChange={(date, dateString) => {
														setFieldValue('carRegistrationDate', dateString);
													}}
													placeholder="차량등록일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											)}
										</GridItem>
										<GridItem
											key="carInspectionRestrictedDate"
											title="검사만료일"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Tag color="blue" style={{ fontSize: '13px' }}>
												{formikValues.carInspectionRestrictedDate || '없음'}
											</Tag>
											{!partnerCase && (
												<DateTimePicker
													name="carInspectionRestrictedDate"
													onChange={(date, dateString) => {
														setFieldValue(
															'carInspectionRestrictedDate',
															dateString,
														);
													}}
													placeholder="검사만료일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											)}
										</GridItem>
										<GridItem
											key="carType"
											title="차종"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Input
												name="carType"
												placeholder="차종"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={!editAll || partnerCase}
												value={formikValues.carType ?? ''}
												onChange={handleChange}
												onReset={() => setFieldValue('carType', '')}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="carInspectionType"
											title="검사종류"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="carInspectionType"
												placeholder="검사종류"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={!editAll || partnerCase}
												value={formikValues.carInspectionType ?? ''}
												onChange={handleChange}
												onReset={() => setFieldValue('carInspectionType', '')}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="carAgeRestrictedDate"
											title="차량만료일"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Tag color="blue" style={{ fontSize: '13px' }}>
												{formikValues.carAgeRestrictedDate || '없음'}
											</Tag>
											{!partnerCase && (
												<DateTimePicker
													name="carAgeRestrictedDate"
													onChange={(date, dateString) => {
														setFieldValue('carAgeRestrictedDate', dateString);
													}}
													placeholder="차량만료일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											)}
										</GridItem>
										<GridItem
											key="rwManagerName"
											title="통화담당자"
											size={GridTitleSize.GT_X_SMALL}
											$isCompact
										>
											<Input
												name="rwManagerName"
												placeholder="통화담당자"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={!editAll || partnerCase}
												value={formikValues.rwManagerName ?? ''}
												onChange={handleChange}
												onReset={() => setFieldValue('rwManagerName', '')}
												$isCompact
											/>
										</GridItem>
										{!partnerCase && (
											<GridItem
												key="proxyInspectionReceptionId"
												title="접수번호"
												$isCompact
												size={GridTitleSize.GT_X_SMALL}
											>
												<Input
													name="proxyInspectionReceptionId"
													placeholder="접수번호"
													type="text"
													$inputSize="small"
													$inputRadius="small"
													$isDisabled
													value={formikValues.proxyInspectionReceptionId ?? ''}
													onChange={handleChange}
													onReset={() =>
														setFieldValue('proxyInspectionReceptionId', '')
													}
													$isCompact
												/>
											</GridItem>
										)}
									</GridLayout>
								</ContentBox>
							</Col>
							<Col
								order={
									inspectionPaymentStatusTabPane?.toUse &&
									inspectionPaymentTabPane.open
										? 3
										: 2
								}
								span={24}
							>
								<ContentBox borderRadius="10px" $isCompact>
									<GridLayout $columnCount={3} $isCompact>
										<GridItem
											key="customerName"
											title="고객명"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="customerName"
												placeholder="고객명"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={!editAll || partnerCase}
												value={formikValues.customerName ?? ''}
												onChange={handleChange}
												onReset={() => setFieldValue('customerName', '')}
												$isCompact
											/>
										</GridItem>
										{!partnerCase && (
											<GridItem
												key="customerPhoneNumber1"
												title="고객연락처"
												$isCompact
												size={GridTitleSize.GT_X_SMALL}
											>
												<Input
													name="customerPhoneNumber1"
													placeholder="고객연락처"
													type="text"
													$inputSize="small"
													$inputRadius="small"
													$isDisabled={!editAll || partnerCase}
													value={formikValues.customerPhoneNumber1 ?? ''}
													onChange={(e) =>
														setFieldValue(
															'customerPhoneNumber1',
															phoneFormat(e.target.value),
														)
													}
													onReset={() =>
														setFieldValue('customerPhoneNumber1', '')
													}
													$isCompact
												/>
											</GridItem>
										)}
										{!partnerCase && (
											<GridItem
												key="customerPhoneNumber2"
												title="고객연락처2"
												$isCompact
												size={GridTitleSize.GT_X_SMALL}
											>
												<Input
													name="customerPhoneNumber1"
													placeholder="고객연락처2"
													type="text"
													$inputSize="small"
													$inputRadius="small"
													$isDisabled={!editAll || partnerCase}
													value={formikValues.customerPhoneNumber2 ?? ''}
													onChange={(e) =>
														setFieldValue(
															'customerPhoneNumber2',
															phoneFormat(e.target.value),
														)
													}
													onReset={() =>
														setFieldValue('customerPhoneNumber2', '')
													}
													$isCompact
												/>
											</GridItem>
										)}
										{!partnerCase && (
											<GridItem
												key="customerCompanyManagerName"
												title="법인담당자"
												$isCompact
												size={GridTitleSize.GT_X_SMALL}
											>
												<Input
													name="customerCompanyManagerName"
													placeholder="법인담당자"
													type="text"
													$inputSize="small"
													$inputRadius="small"
													$isDisabled={!editAll || partnerCase}
													value={formikValues.customerCompanyManagerName ?? ''}
													onChange={handleChange}
													onReset={() =>
														setFieldValue('customerCompanyManagerName', '')
													}
													$isCompact
												/>
											</GridItem>
										)}
										{!partnerCase && (
											<GridItem
												key="customerCompanyPhoneNumber"
												title="법인연락처"
												$isCompact
												size={GridTitleSize.GT_X_SMALL}
											>
												<Input
													name="customerCompanyPhoneNumber"
													placeholder="법인연락처"
													type="text"
													$inputSize="small"
													$inputRadius="small"
													$isDisabled={!editAll || partnerCase}
													value={formikValues.customerCompanyPhoneNumber ?? ''}
													onChange={(e) =>
														setFieldValue(
															'customerCompanyPhoneNumber',
															phoneFormat(e.target.value),
														)
													}
													onReset={() =>
														setFieldValue('customerCompanyPhoneNumber', '')
													}
													$isCompact
												/>
											</GridItem>
										)}
										<GridItem
											key="confirmationPhoneNumber"
											title="확정연락처"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="confirmationPhoneNumber"
												placeholder="확정연락처"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={formikValues.confirmationPhoneNumber ?? ''}
												onChange={(e) =>
													setFieldValue(
														'confirmationPhoneNumber',
														phoneFormat(e.target.value),
													)
												}
												onReset={() =>
													setFieldValue('confirmationPhoneNumber', '')
												}
												$isCompact
											/>
										</GridItem>
										{!partnerCase && (
											<GridItem
												key="customerAddress"
												title="고객주소"
												$isCompact
												size={GridTitleSize.GT_X_SMALL}
												span={3}
											>
												<Input
													name="customerAddress"
													placeholder="고객주소"
													type="text"
													$inputSize="small"
													$inputRadius="small"
													$isDisabled={!editAll || partnerCase}
													value={formikValues.customerAddress ?? ''}
													onChange={handleChange}
													onReset={() => setFieldValue('customerAddress', '')}
													$isCompact
												/>
											</GridItem>
										)}
										<GridItem
											key="modifyCustomerAddress"
											title="확정주소"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
											span={3}
										>
											<Input
												name="modifyCustomerAddress"
												placeholder="확정주소"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={formikValues.modifyCustomerAddress ?? ''}
												onChange={handleChange}
												onReset={() =>
													setFieldValue('modifyCustomerAddress', '')
												}
												$isCompact
											/>
										</GridItem>
									</GridLayout>
									<TextArea
										name="proxyInspectionReceptionMemo"
										placeholder="고객 요청사항을 입력해주세요"
										value={formikValues.proxyInspectionReceptionMemo}
										onChange={handleChange}
										onReset={() =>
											setFieldValue('proxyInspectionReceptionMemo', '')
										}
										style={{ marginTop: '8px' }}
										disabled={partnerCase}
									/>
								</ContentBox>
							</Col>
							<Col
								order={
									inspectionPaymentStatusTabPane?.toUse &&
									inspectionPaymentTabPane.open
										? 2
										: 3
								}
								span={24}
							>
								<ContentBox borderRadius="10px" $isCompact>
									<GridLayout $columnCount={3} $isCompact>
										<GridItem
											key="proxyInspectionOrderCompanyName"
											title="발주처"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="proxyInspectionOrderCompanyName"
												placeholder="발주처"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={
													formikValues.proxyInspectionOrderCompanyName ?? ''
												}
												onChange={handleChange}
												onReset={() =>
													setFieldValue('proxyInspectionOrderCompanyName', '')
												}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="proxyInspectionOrderType"
											title="검사유형"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Select
												value={formikValues.proxyInspectionOrderType ?? ''}
												onChange={(value: string) =>
													setFieldValue('proxyInspectionOrderType', value)
												}
												placeholder="검사유형"
												disabled={partnerCase}
												allowClear
												style={{ fontSize: '14px' }}
											>
												{INSPECTION_ORDER_TYPE.filter(
													(item) => item.value !== ALL_OPTION_VALUE,
												).map((item, idx) => (
													<Select.Option
														value={item.value}
														key={idx.toString()}
													>
														{item.label}
													</Select.Option>
												))}
											</Select>
										</GridItem>
										<GridItem
											key="proxyInspectionOrderDate"
											title="발주일"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Tag color="blue" style={{ fontSize: '13px' }}>
												{formikValues.proxyInspectionOrderDate || '없음'}
											</Tag>
											{!partnerCase && (
												<DateTimePicker
													name="proxyInspectionOrderDate"
													onChange={(date, dateString) => {
														setFieldValue(
															'proxyInspectionOrderDate',
															dateString,
														);
													}}
													placeholder="발주일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											)}
										</GridItem>
										<GridItem
											key="publicCorporationInspectionResult"
											title="진행결과"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Select
												value={
													formikValues.publicCorporationInspectionResult ?? ''
												}
												onChange={(value: string) =>
													setFieldValue(
														'publicCorporationInspectionResult',
														value,
													)
												}
												placeholder="진행결과"
												allowClear
												disabled={partnerCase}
												style={{ fontSize: '14px' }}
											>
												{INSPECTION_RESULT_TYPE.map((item, idx) => (
													<Select.Option
														value={item.value}
														key={idx.toString()}
													>
														{item.label}
													</Select.Option>
												))}
											</Select>
										</GridItem>
										<GridItem
											key="publicCorporationInspectionShopName"
											title="검사소"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="publicCorporationInspectionShopName"
												placeholder="검사소"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={
													formikValues.publicCorporationInspectionShopName ?? ''
												}
												onChange={handleChange}
												onReset={() =>
													setFieldValue(
														'publicCorporationInspectionShopName',
														'',
													)
												}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="publicCorporationInspectionType"
											title="검사종류"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="publicCorporationInspectionType"
												placeholder="검사종류"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={
													formikValues.publicCorporationInspectionType ?? ''
												}
												onChange={handleChange}
												onReset={() =>
													setFieldValue('publicCorporationInspectionType', '')
												}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="publicCorporationInspectionDate"
											title="검사일"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Tag color="blue" style={{ fontSize: '13px' }}>
												{formikValues.publicCorporationInspectionDate || '없음'}
											</Tag>
											{!partnerCase && (
												<DateTimePicker
													name="publicCorporationInspectionDate"
													onChange={(date, dateString) => {
														setFieldValue(
															'publicCorporationInspectionDate',
															dateString,
														);
													}}
													placeholder="검사일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											)}
										</GridItem>
										<GridItem
											key="publicCorporationInspectionResultDate"
											title="검사완료일"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Tag color="blue" style={{ fontSize: '13px' }}>
												{formikValues.publicCorporationInspectionResultDate ||
													'없음'}
											</Tag>
											{!partnerCase && (
												<DateTimePicker
													name="publicCorporationInspectionResultDate"
													onChange={(date, dateString) => {
														setFieldValue(
															'publicCorporationInspectionResultDate',
															dateString,
														);
													}}
													placeholder="검사완료일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											)}
										</GridItem>
										<GridItem
											key="localManagerName"
											title="지역담당자"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="localManagerName"
												placeholder="지역담당자"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={formikValues.localManagerName ?? ''}
												onChange={handleChange}
												onReset={() => setFieldValue('localManagerName', '')}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="publicCorporationInspectionCarMileage"
											title="주행거리"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="publicCorporationInspectionCarMileage"
												placeholder="주행거리"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={numberWithCommas(
													Number(
														formikValues.publicCorporationInspectionCarMileage,
													),
												)}
												onChange={(e) =>
													setFieldValue(
														'publicCorporationInspectionCarMileage',
														String(e.target.value).replace(/[^0-9]/g, ''),
													)
												}
												onReset={() =>
													setFieldValue(
														'publicCorporationInspectionCarMileage',
														0,
													)
												}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="publicCorporationInspectionReceptionNo"
											title="공단접수번호"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
											span={2}
										>
											<Input
												name="publicCorporationInspectionReceptionNo"
												placeholder="공단접수번호"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={
													formikValues.publicCorporationInspectionReceptionNo ??
													''
												}
												onChange={handleChange}
												onReset={() =>
													setFieldValue(
														'publicCorporationInspectionReceptionNo',
														'',
													)
												}
												$isCompact
											/>
										</GridItem>
									</GridLayout>
								</ContentBox>
							</Col>
							{/* </NestedGridWrapper> */}
							<Col order={4} span={24}>
								<ContentBox borderRadius="10px" $isCompact>
									<GridLayout $columnCount={3} $isCompact>
										<GridItem
											key="rwManagerName"
											title="통화담당자"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Input
												name="rwManagerName"
												placeholder="통화담당자"
												type="text"
												$inputSize="small"
												$inputRadius="small"
												$isDisabled={partnerCase}
												value={formikValues.rwManagerName ?? ''}
												onChange={handleChange}
												onReset={() => setFieldValue('rwManagerName', '')}
												$isCompact
											/>
										</GridItem>
										<GridItem
											key="customerName"
											title="불가보고일"
											$isCompact
											size={GridTitleSize.GT_X_SMALL}
										>
											<Tag color="blue" style={{ fontSize: '13px' }}>
												{formikValues.proxyInspectionReceptionCancellationDate ||
													'없음'}
											</Tag>
											{!partnerCase && (
												<DateTimePicker
													name="proxyInspectionReceptionCancellationDate"
													onChange={(date, dateString) => {
														setFieldValue(
															'proxyInspectionReceptionCancellationDate',
															dateString,
														);
													}}
													placeholder="불가보고일 변경"
													style={{ borderRadius: '4px' }}
													disabled={partnerCase}
												/>
											)}
										</GridItem>
										{/* 검사대행 통합 관리자만 보여질것 */}
										{inspectionAgencyAdminRole && (
											<GridItem
												key="proxyInspectionReceptionCancellationReason"
												title="불가사유"
												$isCompact
												size={GridTitleSize.GT_X_SMALL}
											>
												<Select
													value={
														formikValues.proxyInspectionReceptionCancellationReason ??
														''
													}
													onChange={(value: string | null) => {
														setFieldValue(
															'proxyInspectionReceptionCancellationReason',
															value ?? '',
														);
														setFieldValue(
															'proxyInspectionReceptionCancellationDate',
															value ? dayjs().format('YYYY-MM-DD') : '',
														);
													}}
													placeholder="불가사유"
													allowClear
													style={{ minWidth: '120px', fontSize: '14px' }}
												>
													{INSPECTION_CANCELLATION_TYPE.filter(
														(item) => item.value !== ALL_OPTION_VALUE,
													).map((item, idx) => (
														<Select.Option
															value={item.value}
															key={idx.toString()}
														>
															{item.label}
														</Select.Option>
													))}
												</Select>
											</GridItem>
										)}
									</GridLayout>
								</ContentBox>
							</Col>
						</Row>
					)}
				</Col>
				<Col span={10}>
					<TabWrapper>
						<TabList>
							{callHistoryTabPane?.toUse && (
								<Tab
									isActive={callHistoryTabPane.open}
									value=""
									label="통화내역"
									onClick={callHistoryTabPane.handleTabPane}
								/>
							)}
							{inspectionPaymentTabPane?.toUse && transferRole && (
								<Tab
									isActive={inspectionPaymentTabPane.open}
									value=""
									label="지급이체"
									onClick={inspectionPaymentTabPane.handleTabPane}
								/>
							)}
							{transferHistoryTabPane?.toUse && transferRole && (
								<Tab
									isActive={transferHistoryTabPane.open}
									value=""
									label="이체내역"
									onClick={transferHistoryTabPane.handleTabPane}
								/>
							)}
							{inspectionPaymentStatusTabPane?.toUse && transferRole && (
								<Tab
									isActive={inspectionPaymentStatusTabPane.open}
									value=""
									label="지급현황"
									onClick={inspectionPaymentStatusTabPane.handleTabPane}
								/>
							)}

							{settlementTabPane?.toUse && settlementRole && (
								<Tab
									isActive={settlementTabPane.open}
									value=""
									label="정산관리(지급 및 청구)"
									onClick={settlementTabPane.handleTabPane}
								/>
							)}
							{billingTabPane?.toUse && billingRole && partnerCase && (
								<Tab
									isActive={billingTabPane.open}
									value=""
									label="지급요청"
									onClick={billingTabPane.handleTabPane}
								/>
							)}
						</TabList>
					</TabWrapper>
					<TabPaneWrapper>
						{callHistoryTabPane?.toUse && callHistoryTabPane.open && (
							<CallHistoryTabPane
								rwManager={formikValues.rwManagerName}
								inspectionId={inspectionId}
							/>
						)}
						{inspectionPaymentTabPane?.toUse &&
							inspectionPaymentTabPane.open && (
								<InspectionPaymentTabPane
									inspectionId={inspectionId}
									// 차량번호 or 변경번호 전달
									carLicensePlateNumber={formikValues.carLicensePlateNumber}
									// 접수번호 전달
									modifyCarLicensePlateNumber={
										formikValues.modifyCarLicensePlateNumber
									}
									// transferInfoData={initialValues}
								/>
							)}
						{inspectionPaymentStatusTabPane?.toUse &&
							inspectionPaymentStatusTabPane.open && (
								<InspectionPaymentStatusTabPane inspectionId={inspectionId} />
							)}
						{transferHistoryTabPane?.toUse && transferHistoryTabPane.open && (
							<TransferHistoryTabPane inspectionId={inspectionId} />
						)}
						{settlementTabPane?.toUse && settlementRole && (
							<SettlementTabPane
								inspectionId={inspectionId}
								open={settlementTabPane.open}
								onClose={settlementTabPane.onCloseTab}
								settlementInfoData={initialValues}
								detailInfoRefetch={refetch}
							/>
						)}
						{billingTabPane?.toUse && billingRole && partnerCase && (
							<BillingTabPane
								inspectionId={inspectionId}
								billingInfoData={initialValues}
								detailInfoRefetch={refetch}
							/>
						)}
					</TabPaneWrapper>
				</Col>
			</Row>
		</GridDetailTemplate>
	);
};

export default CarServiceDetailTemplate;
